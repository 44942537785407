import React from 'react';

import Header from "./Header.js";
import Sobre from './Sobre.js';
import Localizacao from './Localizacao.js';
import Studiodisp from './studiosdisp.js';
import AreasComuns from './AreasComuns.js';
import Arquitetura from './Arquitetura.js';
import Contato from './Contato.js';
import Footer from './Footer.js';

const Home = () => {
    return(
        <div>
            <Header />
            <Sobre /> 
            <AreasComuns /> 
            <Localizacao />
            <Studiodisp />   
            <Arquitetura /> 
            <Contato />
            <Footer />  
        </div>
    )
}

export default Home;