import React from 'react';

import "./style/localizacao.css";

const Localizacao = () => {
    return(
       <div className='localmor'>
            <div className='mapa' data-aos="fade-right" data-aos-duration="1500" data-aos-delay="300">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.406077615651!2d-49.285268900000005!3d-25.424683999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce3f81660ca43%3A0xebf65c44e96dc9f8!2sAlameda%20Prudente%20de%20Moraes%2C%20298%20-%20Centro%2C%20Curitiba%20-%20PR%2C%2080430-234!5e0!3m2!1spt-BR!2sbr!4v1727135878073!5m2!1spt-BR!2sbr" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className='localtexto' data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                <h2>LOCALIZAÇÃO</h2>
                <h2 className='priv'>PRIVILEGIADA</h2>
                    <p>
                    Empreendimento situado em uma área estratégica com alta taxa de ocupação no 
                    short stay (AirBnb e outros...). Contando com uma infraestrutura moderna, o empreendimento se destaca como uma opção 
                    atrativa para quem busca conveniência e conforto em uma região nobre de Curitiba. 
                    Além disso, a localização privilegiada facilita o acesso a principais pontos de
                    interesse, aumentando ainda mais a procura e garantindo uma operação eficiente
                    e lucrativa.
                    </p>
            </div>
       </div>
    )
}

export default Localizacao;