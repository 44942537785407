import React from "react"

import './style/footer.css'

const Footer = () => {
    return (
        <div className="footermor">
            <img src="concrezatto.png" className="footerimg"></img>
            <p>Imagens meramente ilustrativas. Mobiliário/eletrônicos não inclusos. Possíveis alterações de projeto podem ocorrer. Consulte memorial
            descritivo do empreendimento. Valores/condições sujeitos a alteração sem prévio aviso. Matrícula 57473 - 1ª Circunscrição de Curitiba.</p>

            <a href="">Política de Privacidade</a>
        </div>
    )
}

export default Footer