import React from 'react';

import "./style/arquitetura.css";

const Arquitetura = () => {
    return (
        <div className='arquiteturamor'>
            <div className='arqtexto' data-aos="fade-right" data-aos-duration="1500" data-aos-delay="100">
                <div>
                    <a href="https://www.vgarquitetos.com.br/">
                 <h5 className='arqh'><p id='arqhp'>ARQUITETURA</p><br/> VINICIUS GUIMARÃES</h5>
                 </a>
                 </div>
                <p>
                Contemporaneidade, versatilidade e conforto. Esses foram os pilares para o 
                projeto do <strong>ELOS.</strong> O foco central 
                foi traduzir o estilo de vida que busca a praticidade e o bem-estar, mas com 
                um design arrojado,
                 com destaques em elementos de concreto aparente. Já as curvas da volumetria 
                 trazem a suavidade e o equilíbrio ao projeto. A configuração dos apartamentos
                  prioriza a funcionalidade da vida moderna. De mesmo modo, as plantas compactas
                   foram pensadas para trazer praticidade e conforto. As áreas comuns do prédio
                    são verdadeiras extensões dos apartamentos, tendo como objetivo reunir, em 
                    um só lugar, espaços de lazer, trabalho e serviços, atendendo às necessidades 
                    dos moradores.
                </p>
               
            </div>
            <div>
                <img src="./arquitetura.jpeg" className='arqimg' data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300"></img>
            </div>  
        </div>
    )
}

export default Arquitetura;