import React, { Component } from "react";
import Slider from "react-slick";
import "./style/carrousel.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="Carrou">
        <Slider {...settings}>
        <div>
            <img className="Carrousel_image" src="gourmet.jpg"></img>
            <p>Espaço gourmet</p>
          </div>
          <div>
            <img className="Carrousel_image" src="areagourmet2.jpg"></img>
            <p>Espaço gourmet</p>
          </div>
          <div>
            <img className="Carrousel_image" src="academia.jpg"></img>
            <p>Academia</p>
          </div>
          <div>
            <img className="Carrousel_image" src="academia2.jpg"></img>
            <p>Academia</p>
          </div>
          <div>
            <img className="Carrousel_image" src="spa.jpg"></img>
            <p> Spa </p>
          </div>
          <div>
            <img className="Carrousel_image" src="spa2.jpg"></img>
            <p> Spa </p>
          </div>
          <div>
            <img className="Carrousel_image" src="coworking.jpg"></img>
            <p> Coworking</p>
          </div>
          <div>
            <img className="Carrousel_image" src="coworking-e.jpg"></img>
            <p> Coworking (pátio)</p>
          </div>
          <div>
            <img className="Carrousel_image" src="lavanderia.jpg"></img>
            <p> Lavanderia</p>
          </div>
          <div>
            <img className="Carrousel_image" src="lavanderia2.jpg"></img>
            <p> Lavanderia</p>
          </div>
          <div>
            <img className="Carrousel_image" src="mercado.jpg"></img>
            <p>Mini market</p>
          </div>
          <div>
          <img className="Carrousel_image" src="hall.jpg"></img>
            <p>Hall</p>
          </div>
          <div>
          <img className="Carrousel_image" src="hall2.jpg"></img>
            <p>Hall</p>
          </div>
        
        </Slider>
      </div>
    );
  }
}