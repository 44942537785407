import React from 'react'

import "./style/contato.css";

const Contato = () => {
    return (
        <div className='contatomormor' id="contato">
            <h6>CADASTRE-SE PARA O LANÇAMENTO</h6>
            <div className='contatomor'>
                <div className='contatosemimor'>
                <div className='contatoT'>
                            <p className="text_info" data-aos="fade-up" data-aos-duration="1500" >
                            <b style={{textTransform:'uppercase'}}>Plantão de Vendas</b> <br/>
                            Rua Alameda Prudente de Moraes, 298 - Mercês<br/>
                            <br/>
                            <b style={{textTransform:'uppercase'}}>Entre em contato pelo WhatsApp</b> <br/>
                            <a href="https://api.whatsapp.com/send?phone=5541996480900" style={{color:'inherit'}}>41 99648.0900 </a><br/>
                            <br/>
                            <b style={{textTransform:'uppercase'}}>Siga-nos no Instagram</b> <br/>
                            <a style={{color:'inherit'}}href="https://www.instagram.com/concrezatto_construtora/?igshid=OTJlNzQ0NWM%3D">@concrezatto_construtora </a><br/>
                            <br/>
                            <b style={{textTransform:'uppercase'}}>Venda exclusiva</b> <br/>
                            Concrezatto Construtora<br/>
                        </p>
                </div>
                <div className='contatoForm'>
                <form class="send-emails"  action="https://formsubmit.co/ajax/8496f8518821beeaacc3af3cc641783a"
                method="POST"
                data-form data-aos="fade-up" data-aos-duration="2000">
                    <div className="field" tabindex="1">
                        <label for="username">
                            <i className="far fa-user"></i>Seu nome
                        </label>
                        <input class="nome" name="from_name"   type="text" placeholder="ex: joão" required/>
                    </div>
                    <div className="field" tabindex="2">
                        <label for="email">
                            <i className="far fa-envelope"></i>Seu email
                        </label>
                        <input name="reply_to" class="email"type="text"  placeholder="ex: email@dominio.com" required/>
                    </div>
                    <div className="field" tabindex="3">
                        <label for="message">
                            <i className="far fa-edit"></i>Deixe uma mensagem
                        </label>
                        <textarea name="message" placeholder="Escreva aqui" required></textarea>
                    </div>
                    <button className='botao' type="submit">ENVIAR MENSAGEM</button>
                    
                    </form>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Contato